import React, {ReactElement, useEffect, useState} from "react";
import Cookies from "universal-cookie";
import {GET} from "../../api/fetch_wrapper";
import {
    Button,
    ButtonGroup,
    Divider,
    LinearProgress,
    List,
} from "@mui/material";
import {FitbitHeartRateTimeBlock, simplify} from "../../api/interfaces/fitbit_activity";
import Container from "@mui/material/Container";
import {useNavigate} from 'react-router-dom';
import WeekPicker from '../../widgets/week_picker'
import {Circle, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {LeaderboardEntry, LeaderboardEntryProps} from "./entry";
import { addDays } from 'date-fns'
import AboutFitbitLeaderBoard from "./about";


function calculateWeekOffset(date: Date): number {
    const today = new Date();
    return Math.round((date.valueOf() - today.valueOf()) / (7 * 24 * 60 * 60 * 1000))
}


function calculateLeaderboardEntry(x: FitbitLeaderboardResponse): LeaderboardEntryProps {
    const simplified = x.data.map(simplify);
    const minutes = simplified.reduce((prev, curr) => {
        return {
            active: prev.active + curr.active,
            cardio: prev.cardio + curr.cardio,
            peak: prev.peak + curr.peak,
        };
    });

    return {
        total_minutes: (2 * (minutes.peak + minutes.cardio)) + minutes.active,
        data: simplified,
        user_id: x.user_id,
        username: x.username,
        avatar: x.avatar,
        is_leader: false
    }
}


interface FitbitLeaderboardResponse {
    data: FitbitHeartRateTimeBlock[];
    user_id: string;
    username: string;
    avatar: string;
}

export function FitbitActivityLeaderboard(): ReactElement {
    const [dayInWeek, setDayInWeek] = useState<Date>(new Date());
    const [leaderboard, setLeaderboard] = useState<LeaderboardEntryProps[]>([]);
    const [weekOffset, setWeekOffset] = useState<number>(0);
    const cookies = new Cookies();
    const nav = useNavigate();

    async function fetch(user_id: string) {
        console.log(`Fetching leaderboard data for week offset ${weekOffset}`)
        const response = await GET<FitbitLeaderboardResponse[]>(`/fitbit/leaderboard/${user_id}/${weekOffset}`);
        if (response?.message === "unauthorized") { nav("/fitbit/auth"); }
        else {
            const leaderboard = response?.data?.map(calculateLeaderboardEntry).sort(
                (a, b) => {
                    if (isNaN(a.total_minutes)) { return 1; }
                    if (isNaN(b.total_minutes)) { return -1; }
                    return b.total_minutes - a.total_minutes;
                });

            if (leaderboard) {
                leaderboard[0].is_leader = true;
                setLeaderboard(leaderboard);
            }
        }
    }

    useEffect(() => {
        const user_id = cookies.get('user_id');
        if (leaderboard?.length === 0 && user_id)
            { fetch(user_id); }
        if (!user_id)
            { nav("/fitbit/auth"); }
    });

    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', padding: "10px", marginTop: "10px"}}>
            <Container maxWidth="sm" style={{backgroundColor: '#fafafa', padding: "10px", marginTop: "10px"}}>
                <WeekPicker dayInWeek={dayInWeek} onWeekChange={(newValue) => {
                    if (!newValue) { return; }
                    console.log(`Changed to week of ${newValue}`);
                    setDayInWeek(newValue);
                    setWeekOffset(calculateWeekOffset(newValue));
                    setLeaderboard([]);
                }}/>
                <ButtonGroup variant="text" size="small" sx={{ paddingTop: "10px", paddingLeft: "20px"}}>
                    <Button onClick={() => {
                        setDayInWeek(addDays(dayInWeek,-7));
                        setWeekOffset( weekOffset - 1);
                        setLeaderboard([]);
                    }}>
                        <KeyboardArrowLeft color="action"/>
                    </Button>
                    <Button onClick={() => {
                        if (weekOffset === 0) { return; }
                        setDayInWeek(new Date());
                        setWeekOffset(0);
                        setLeaderboard([]);
                    }}>
                        <Circle fontSize="small" color="action"/>
                    </Button>
                    <Button onClick={() => {
                        if (weekOffset === 0) { return; }
                        setDayInWeek(addDays(dayInWeek,7));
                        setWeekOffset(weekOffset + 1);
                        setLeaderboard([]);
                    }}>
                        <KeyboardArrowRight color={weekOffset !== 0 ? "action" : "disabled"}/>
                    </Button>
                </ButtonGroup>
                <Divider sx={{ paddingTop: "10px" }} />
                <List component="nav" aria-label="mailbox folders">
                    {
                        leaderboard?.length > 0 ?
                        leaderboard?.map((x) => {return <LeaderboardEntry {...x}/>}) : <LinearProgress/>
                    }
                </List>
            </Container>

        <AboutFitbitLeaderBoard/>
        </Container>
    );
}