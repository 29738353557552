import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import ReactMarkdown from "react-markdown";
import {Divider, List, ListItem, ListItemText} from "@mui/material";

const md = `
My name is Matt Zapp, and I wear many hats. This website is my hat rack. 

## About the Apps
One of my hats is that of a Software Engineer, where I write code that does things. Sometimes it does things that other
people like or need, and sometimes it just does things that I think are neat. The sidebar contains links to various apps
and projects that fall into both categories, please explore and enjoy.
`

export default function Home(): ReactElement {
    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', padding: "10px", marginTop: "10px"}}>
            <h1>Welcome to Matt's Apps</h1>
            <Divider/>
            <div style={{textAlign: "left", paddingBottom: "1px" }}>
                <ReactMarkdown children={md}/>
            </div>
        </Container>
    );
};
