import React, {ReactElement, useState} from "react";
import {
    Container,
    Divider,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import TextField from "@mui/material/TextField";
import AppHeaderElement from "../../style/app_header";
import AboutDrumPlyCalculator from "./about";
import Typography from "@mui/material/Typography";


export default function DrumPlyCalculatorApp(): ReactElement {
    const [diameter, setDiameter] = useState<number>(14);
    const [thickness, setThickness] = useState<number>(0.022);
    const [plyCount, setPlyCount] = useState<number>(8);
    const [skinReductionRatio, setSkinReductionRatio] = useState<number>(0.125);

    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', marginTop: "10px"}}>
            <AppHeaderElement title={"Drum Ply Calculator"}/>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant="h6">Drum Diameter</Typography>
                    <TextField id="drum_diameter" type="number" value={diameter}
                               onChange={x => setDiameter(parseInt(x.target.value))} InputProps={{ inputProps: { min: 1 }}} size="small"/>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6">Ply Count</Typography>
                    <TextField id="ply_count" type="number" value={plyCount}
                               onChange={x => setPlyCount(parseInt(x.target.value))} InputProps={{ inputProps: { min: 2 }}} size="small"/>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"h6"}>Veneer thickness</Typography>
                    <TextField id="veneer_thickness" type="number" value={thickness}
                               onChange={x => setThickness(parseFloat(x.target.value))} InputProps={{ inputProps: { min: 1 }}} size="small"/>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"h6"}>Skin Reduction Ratio</Typography>
                    <TextField id="skin_reduction_ratio" type="number" value={skinReductionRatio}
                               onChange={x => setSkinReductionRatio(parseFloat(x.target.value))} InputProps={{ inputProps: { min: 0 }}} size="small"/>
                </Grid>
            </Grid>

            <Divider sx={{marginTop: "8px"}}/>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Ply Number</TableCell>
                        <TableCell>Ply Length</TableCell>
                    </TableRow>
                </TableHead>
                {
                    Array.from({length: plyCount}).map((x, i) => {
                        const ply = i + 1;
                        const realDiameter = diameter - skinReductionRatio;
                        return(
                            <TableRow>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{(realDiameter - 2 * ply * thickness) * Math.PI}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </Table>
            <AboutDrumPlyCalculator/>
        </Container>
    )
}