import {MinutesBlock} from "../../api/interfaces/fitbit_activity";
import React, {ReactElement} from "react";
import {Bar, BarChart, Tooltip, XAxis, YAxis} from "recharts";

interface ChartProps {
    data: MinutesBlock[];
}

export default function LeaderboardChart(props: ChartProps): ReactElement {
    const days = ['Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun']
    const data = days.map((x, i) => {
        return {
            active: i < props.data.length ? props.data[i].active : 0,
            cardio: i < props.data.length ? props.data[i].cardio * 2 : 0,
            peak: i < props.data.length ? props.data[i].peak * 2 : 0,
            day: x
        };
    });
    return (
        <BarChart width={600} height={300} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <XAxis name="date" dataKey="day"/>
            <YAxis domain={[0, 120]}/>
            <Tooltip/>
            <Bar stackId="a" dataKey="active" fill="#2554d8"/>
            <Bar stackId="a" dataKey="cardio" fill="#ee9f00"/>
            <Bar stackId="a" dataKey="peak"   fill="#ee6338"/>
        </BarChart>
    );
}
