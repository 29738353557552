import Modulator, {TargetType, ShapeType} from "../modulator_interfaces";
import {FormControl, InputLabel, List, ListItem, MenuItem, Select, TextField} from "@mui/material";
import React from "react";

interface ModulatorEditorProps extends Modulator {
    onModulatorChange: (mod: Modulator) => void;
}



export default function ModulatorEditor(props: ModulatorEditorProps) {
    return (
        <List>
            <ListItem>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="target-input">Target</InputLabel>
                    <Select id="target-input" label={"target"} onChange={(x) => {
                        props.onModulatorChange({...props, target: x.target.value as TargetType})
                    }}>
                        <MenuItem value={"hue"}>Hue</MenuItem>
                        <MenuItem value={"saturation"}>Saturation</MenuItem>
                        <MenuItem value={"lightness"}>Lightness</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="shape-input">Shape</InputLabel>
                    <Select id="shape-input" label={"shape"}>
                        <MenuItem value={"triangle"}>Triangle</MenuItem>
                        <MenuItem value={"noise"}>Noise</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
            <ListItem>
                <TextField
                    id="standard-number"
                    label="Speed"
                    type="number"
                    InputLabelProps={{shrink: true}}
                    variant="standard"
                    value={props.speed}
                    size={"small"}
                    sx={{m: 1}}
                    onChange={(x)=> {
                        props.onModulatorChange({...props, speed: parseFloat(x.target.value)})
                    }}
                />
                <TextField
                    id="standard-number"
                    label="Depth"
                    type="number"
                    InputLabelProps={{shrink: true}}
                    variant="standard"
                    value={props.depth}
                    size={"small"}
                    sx={{m: 1}}
                    onChange={(x)=> {
                        props.onModulatorChange({...props, depth: parseFloat(x.target.value)})
                    }}
                />
            </ListItem>
        </List>
    )
}
