import React, {ReactComponentElement, ReactElement} from "react";
import {Collapse, IconButton, Typography} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export interface AppHeaderProps {
    title: string;
    infoComponent?: ReactComponentElement<any>
}



export default function AppHeaderElement(props: AppHeaderProps): ReactElement {
    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom component="div" sx={{p: 2, pb: 0}}>
                {props.title}
            </Typography>
        </React.Fragment>
    );
}