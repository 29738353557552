import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";

const md = `
# About the Light Designer
This controls the colors and animation of the lights behind the shelves in my music room.

## To Use
This is a work in progress, mostly I end up using raw payloads to handle updating the colours, but the idea is thus:

There are three channels, one for each shelf. The large square is a preview of what the LEDs will do in real life.

You can add or remove colours from each channel, and set the time it will take to transition from one colour to the
next.

You can also add or remove modulators which will affect the whole channel's animation. Each modulator and can
independently adjust the hue, saturation, and value of the channel.  

## History
My small music room has some shelves behind which I installed some RGB strips. The RGB LED tape came with a remote that
was rather chintzy and limited. It could only either cycle through RGB or select one solid color, and I had this vision
of an orange ombre, with some mild flickering, to give the impression of a cozy fireplace. 

I also knew I was bound to want a different look at some point, and have to re-wire or go digging behind the various
racks of gear to re-program things every time my whims shifted. So to mitigate that, I over-engineered the s**t out of a
solution:
  
1. Get a raspberry pi
2. Get a few RGB PWM controllers, one for each shelf
3. Hook the pi up to AWS IoT
4. Make a service to run on the pi to link payloads received on an MqTT channel with animations of each PWM controller
5. Make this UI to send payloads to that MqTT channel.

This is similar to how Hue bulbs work too, but hue bulbs don't have the fancy animation options, at least not built in,
and their user experience is much better in pretty much every conceivable way.

You may ask "why did Matt expose the lights in his office to a bunch of randos on the internet?" and this is a good
question. The answer is, that if you want to troll me, go right ahead. I kind of can't wait to see what colors you
choose. And if for some reason I get enough traffic here and it starts getting to be too much, or people start to rack
up my AWS bills, then I'll disable the IoT part and no one will ever know.   
`

export default function AboutLightDesigner(): ReactElement {
    return (
        <div style={{textAlign: "left", paddingBottom: "1px" }}>
            <ReactMarkdown children={md}/>
        </div>
    );
}
