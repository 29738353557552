import React, {ReactElement, useEffect, useState} from "react";
import {Divider, List, ListItem, Paper, TextField} from "@mui/material";
import ColourList from "./colour_list";
import ModList from "./modulation/mod_list";
import ColourResultDisplay from "./colour_result_display";
import {HSLColour} from "./colour_interfaces";
import Modulator from "./modulator_interfaces";


export interface LightChannelProps {
    id: string
    colours: HSLColour[];
    rotateColorSeconds: number;
    modulators: Modulator[];
    onColourChange: (newColours: HSLColour[]) => void;
    onRotationChange: (newSpeed: number) => void;
    onModulatorChange: (newModulators: Modulator[]) => void;
}

function makeState() {
    return Array.from({length: 3}, (v, k)=>k).map(k => ({
        index: k, content: `item ${k}`
    }))
}

export default function LightChannel(props: LightChannelProps): ReactElement {
    return (
        <ListItem>
            <ColourResultDisplay colours={props.colours} lengthSeconds={props.rotateColorSeconds}/>
            <List>
                <ListItem>
                    <ColourList colours={props.colours} onColourChange={props.onColourChange}/>
                    <Divider orientation="vertical" flexItem sx={{marginRight: "10px"}}/>
                    <TextField
                        id="standard-number"
                        label="Rotate Speed (ms)"
                        type="number"
                        InputLabelProps={{shrink: true}}
                        variant="standard"
                        value={props.rotateColorSeconds}
                        size={"small"}
                        onChange={(x)=>(props.onRotationChange(parseFloat(x.target.value)))}
                    />
                </ListItem>
                <ListItem>
                    <ModList modulators={props.modulators} onModChange={props.onModulatorChange}/>
                </ListItem>
            </List>
        </ListItem>
    )
}