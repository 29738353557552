import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";

const md = `
# About the Drum Ply Calculator
Plywood drum making requires cutting thin sheets of raw wood veneer to very precise lengths, and forming them into a
rigid negative mold. This calculator produces the length of each layer to be cut for a given number of plys in the drum.

## To use
The calculator is agnostic to your units as long as they are all the same unit. Drums have traditionally been measured 
in full inches.

Set the drum diameter to the target size of your drum. Note that, to accommodate the skin, all output measurements will
be for a shell that is 1/8 of a unit shorter across in diameter. This ratio can be adjusted using the "skin reduction
ratio" parameter if you plan on using some kind of hifalutin unit like centimeters.

Set the thickness of your veneer. It is assumed that all your veneer is the same thickness. If you want to complain
about that send me an e-mail. If you are purchasing your veneer, note that its thickness may not be measured in inches.
I have been burned by this before.

Each layer will appear in a table below the inputs. You can decide based on time, and access to an electron microscope, 
just how accurate you want to get with your cuts of each layer.

Be aware that if you are forming alternating plys into a single veneer for stability before inserting into the
mold, you must take all the *even* ply's lengths for these type of double plys, skipping every other.

The defaults are for a 14-inch drum using 1/42-inch veneer.

## History
Outside my day-job as a software engineer, one of the many hats in my arsenal are those of a drummer and a woodworker. 
I wanted to try my hand at making drums to do some hands-on investigation on how different parameters impact a drum's
sound, usability, playability etc. 

To aid cutting my plys to the right length and estimating material costs, I built this tool. The first iteration of this
was an excel spreadsheet. Its core is a super simple equation which calculates the circumference of a progressively 
shrinking circle:

\`ply_length = (actual_drum_diameter - 2 * ply_index * veneer_thickness) * PI\`
 
Plywood drum building isn't terribly complicated in theory, but there isn't as huge a trove of info and support
for it the way there is for guitar building either, (I suppose Luthiery is sexier,) so I wanted to add my little bit to
what there is, and moved it into this React app.
`

export default function AboutDrumPlyCalculator(): ReactElement {
    return (
        <div style={{textAlign: "left", paddingBottom: "1px" }}>
            <ReactMarkdown children={md}/>
        </div>
    );
}
