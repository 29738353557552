import React, {ReactElement} from 'react';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
    DroppableStateSnapshot
} from 'react-beautiful-dnd';
import {HSLColour} from "./colour_interfaces";
import DraggableColour from "./draggable_colour";
import { IconButton } from "@mui/material";
import {AddBox} from "@mui/icons-material";

const grid = 8;

// a little function to help us with reordering the result
const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});


interface ColourListProps {
    colours: HSLColour[];
    onColourChange: (newColours: HSLColour[]) => void;
}

export default function ColourList(props: ColourListProps): ReactElement {
    function onDragEnd(result: DropResult) {
        if (!result.destination) { return props.colours; }

        const rv = Array.from(props.colours);
        const [removed] = rv.splice(result.source.index, 1);
        rv.splice(result.destination?.index, 0 , removed);
        props.onColourChange(rv);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                    >
                        {props.colours.map(
                            (colour, index) => (
                                <DraggableColour index={index} colour={colour} onColourChange={(colour) => {
                                    // If there's no color, it's because it's been removed
                                    if (!colour) { props.colours.splice(index, 1); }
                                    else         { props.colours[index] = colour }
                                    props.onColourChange(props.colours);
                                }}/>)
                        )}
                        {provided.placeholder}
                        <IconButton onClick={()=>{
                            const newColour = props.colours.length >= 0
                                            ? props.colours[props.colours.length - 1]
                                            : {h:0, s:0.5, l: 0.5}
                            props.onColourChange([...props.colours, newColour]);
                        }}>
                            <AddBox/>
                        </IconButton>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}