import React, {ReactElement, useEffect, useState} from "react";
import {Container, List} from "@mui/material";
import LightChannel from "./channel";
import ColourDesignerState from "./designer_state";
import {HSLColour} from "./colour_interfaces";
import Modulator from "./modulator_interfaces";
import AboutLightDesigner from "./about";

const chan1TmpColours = [
    { h: 0, l: 0.5, s: 1.0 },
    { h: 0.85, l: 0.5, s: 1.0 },
    { h: 0.5, l: 0.5, s: 1.0 }
]


const chan2TmpColours = [
    { h: 0.5, l: 0.5, s: 1.0 },
    { h: 0.10, l: 0.5, s: 1.0 }
]

const chan3TmpColours = [
    { h: 0.3, l: 0.5, s: 1.0 },
    { h: 0.85, l: 0.5, s: 1.0 }
]


export default function LightDesignerApp(): ReactElement {
    const [state, setState] = useState<ColourDesignerState>({
        channels: [
            {colours: chan1TmpColours, modulators: [], rotateColorSeconds: 5},
            {colours: chan2TmpColours, modulators: [], rotateColorSeconds: 5},
            {colours: chan3TmpColours, modulators: [], rotateColorSeconds: 5},
        ],
        speedRotateChannels: 0
    })

    function setChannelState(chan: number, colours: HSLColour[], modulators: Modulator[], rotateSpeed: number){
        console.log("setting colors for channel:", chan, colours);
        state.channels[chan].colours = colours;
        state.channels[chan].rotateColorSeconds = rotateSpeed;
        state.channels[chan].modulators = modulators;
        return  {...state};
    }

    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', padding: "10px", marginTop: "10px"}}>
            <Container maxWidth="sm" style={{backgroundColor: '#fafafa', padding: "10px", marginTop: "10px"}}>
                <List>{
                    state.channels.map((chan, i) => <LightChannel
                        id={`${i}`}
                        colours={chan.colours}
                        modulators={chan.modulators}
                        rotateColorSeconds={chan.rotateColorSeconds}
                        onColourChange={(newColours) => {setState(setChannelState(i, newColours, chan.modulators, chan.rotateColorSeconds));}}
                        onRotationChange={(newSpeed) => {setState(setChannelState(i, chan.colours, chan.modulators, newSpeed));}}
                        onModulatorChange={(newModulators) => {setState(setChannelState(i, chan.colours, newModulators, chan.rotateColorSeconds));}}
                    />)
                }</List>
            </Container>
            <AboutLightDesigner/>
        </Container>
    )
}