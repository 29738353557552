import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";
import {Container, Box} from "@mui/material";
import AppHeaderElement from "../../style/app_header";

const mdpt1 = `
As established earlier, the Playdate is a minimalist system. This extends to the languages that are supported by Panic,
the software team running the show. They have an SDK available in Lua and C, and that's yer lot...

Except now you can use C++!

* [**playdate-cpp**](https://github.com/nstbayless/playdate-cpp) -- A boilerplate library for building playdate applications
  and Lua extensions using C++. The extensions build off this library, so start here.
* [**playdate-cpp-extensions**](https://github.com/metaphaseaudio/playdate-cpp-extensions) -- A library which aims to
  enable developers to use exclusively C++ when making playdate games and Lua extensions.
`;

const toUse = `## To Use
These packages are meant to be included as git submodules in your repo, and added as subdirectories in your CMake 
project.
 
One day I might try to get them both in a state where one can build/install them independently and then use 
\`find_package\`, but for now, this works, and allows me to develop on multiple things in parallel. For explicit
details, check out the README.md files in each package.

### CPPong
There's a version of Pong in the repo to help demonstrate how one can reasonably create a full game using the
extensions.
`
const part2 = `
Obviously a game as simple as Pong cannot cover everything you can imagine, but it does touch on more than one might
immediately expect, highlighting the basics of how to handle user control, graphics, and sound.

## History
### Inspiration and constraint
A friend introduced me to the Playdate, and expressed interest in making something with the system. I quickly had a few
ideas about things to do with it, so was keen to go down the rabbit hole with them.

As mentioned earlier, there are two APIs for the platform, one for in Lua, and one in C. While I like C well enough,
(and Panic's C API is one of the friendliest I've ever encountered,) the Lua API is more fully-featured, and simpler to
use, so better for collaboration. I'd never used Lua before, but am very comfortable moving between languages, there
was solid documentation on how to set up a development environment, and after some pretty quick wins, I was sold: Use
Lua, make games, Done.

However, once the projects started to grow, I found that my IDE struggled to support code completion, or
"go-to definition" across files within my project. Things worked within a file, but I couldn't navigate outside of that.

It may seem petty, but this was a deal-breaker. I am a scatterbrained monkey who types like an uzi: quickly and without
any accuracy. I need all the help I can get. I rely on code completion to finish my thoughts, and make sure I don't make
typos. I rely *heavily* on go-to definition to allow me to change scopes and follow code flow without breaking my train
of thought. 

With this restriction, navigating the project would get continually worse as more code was written. A full game might
contain thousands of lines of code spread across dozens of files. With more than one contributor, the fog of war
would quickly overwhelm the joy of ideation, ensuring the demise of any project past a certain scale.

### Just keep digging
I reached out to the developer forum for help, and even got the ear of a moderator from Panic who was empathetic, but
unable to help. An alternative would be required in order to be successful. My initial, naive, attempts to build with
C++ were met with a cornucopia of compiler and linker errors.

Frustrated, but stubborn, I took a closer look at an old post in the Playdate developer forum that had demonstrated C++
working on the playdate in a long out-of-date version of the SDK. As old posts in developer forums often do, it had
bit-rotted substantially: it seemed to have only ever worked on Linux, required wholesale over-writes of large chunks of
the SDK, and flat out didn't work with anything newer than v2.x.x of the SDK.

However, my autopsy located two the keys to \`playdate-cpp\`'s functionality:
1. There were a number of boilerplate C++ functions normally provided by the linker map file and glibc that needed to be
   defined specifically for the Playdate.
2. The build system declared certain flags and properties that would lead to crashes if not set "just so."
   
For the first issue, newer versions of the SDK had libgcc, libc, and libm all in scope in their linker map, so it turned
out that v2.x.x was actually *easier* to port to C++ than it had been previously (the original author seemed to have had
a hand in this.) The core C files could be pared down, so long as the linker map file was amended to include some
exception-handling symbols (which are still not working right, the library will need Panic's help there.)

For the second, the challenge wasn't necessarily replicating the flags, but finding them all. Cross-referencing the
Panic build system with \`playdate-cpp\`'s, managed to dig them all out. I tinkered with a few of these, but certainly
haven't mastered the system. There may be further improvements to be made.

### Flip it on its head
After establishing what made the machine tick, the next challenge became minimizing the surgery required for the C API.

The hitch there turned out to be the dependency model in use by the C API. The original build system expects users to
include the headers and linker map directly into their projects, This model required projects to be in C, and converting
to C++ required serious surgery to the API, with new makefiles, \`extern "C"\` guards, etc.

To work around this, I constructed a CMake build system which inverted that dependency model, building the C API as a
static library, linking in a map maintained in the C++ repository, and allowing users to fully de-couple the SDK code
from their own. The only surgery required now is to comment out a forward declaration in the \`pd_api.h\` file, which
doesn't impact any of the existing Playdate C_API examples.

With this new system established, building the C API in a user's CMake project required three lines of code in the cmake
project, one line in the entry point of the actual C++ code, and "things just worked."

### Discovering a community
The road was now open, but the work didn't feel "done."

Perhaps it was the result of being burned one too many times by ["The Wisdom of the Ancients"](https://xkcd.com/979/),
but I felt an obligation to publish the solution, even if there were only a handful of people who were even remotely
interested in using C++ for Playdate development.  

That said, I wasn't in a particularly comfortable position to do so. I had taken someone's project, ripped it apart, and
put it all back together such that the result was barely recognizable. Filing such a pull request against the project
felt, at the very least, like bad form, and more like disrespect, or an admission of a serious desecration.

It felt prudent to reach out for guidance from the original author on the Playdate forum. If they didn't respond, or
weren't willing to take on such dramatic changes, the work could still be published as a fork, but their repo was
actively showing up in searches, so the impact would be greater if they would accept a PR.

The author was more receptive than I could have hoped for, and after some minor coordination, the PR was accepted, and
they anointed me as a contributor to the project with permissions to keep the project up to date. A quick post to the
original thread on the Playdate developer forum notified anyone who was following it, and the project was officially
back on line.

### Extending out the long tail
As previously established, the motivation for resuscitating \`playdate-cpp\`, was not altruistic, but a necessary
compromise between the competing goals of wanting to work with others, and the others not wanting to work with C. As the
API to the hardware was still very much in C, establishing a build system for C++ was only the first hurdle, and a
considerable chunk of work remained: the API would have to be translated.

Enter the \`playdate-cpp-extensions\` library.

In the spirit of full disclosure, the intention was never to replicate the entire API in C++ in one fell swoop.
Initially only the translations needed to support one specific project were made, and anything that fell outside of that
scope wasn't touched at all. Things continued in this fashion until the library reached a point where it felt reasonably
"feature complete." This feeling was predicated on the CPPong example being created in roughly a weekend. If a full game
could be created with the library in its current state, surely it was ready for the limelight.

### The runner's mentality 
It was only when setting out to write the Doxygen comments for all the classes that the scope of what was missing
became apparent. Large swaths of the API, in particular the audio engine, remained unsupported.

This would not do. My career and identity as a developer is rooted in audio. Audio *in the name of the GitHub account*
to which this library was being published, and yet the *entire* audio engine was unsupported? It would be a catastrophic
dereliction of duty, so I set about rectifying it.

However, once started down that road, reality set in about the scale of the effort. Translation became a marathon -- the
only thing maintaining forward progress was unyielding discipline. Every day I would target a part of the API to
translate and document. Slowly the pieces fell into place, until finally, the job was done, and v0.1.0 could be
published officially on the developer forum.

### Denoument
Clearly, this was a lot of work. The Playdate developer community is not large, and the C++ community within that sphere
represents a very small subsection of that already small group. It is tempting to consider the effort-to-reward ratio,
and abandon efforts like this as "not worth it," but I regard that mindset as a trap. The world is better off for having
more avenues for creativity, even if only one person ever uses it, even if that one person is me.

That is the beauty of Open Source Software. It's built from the idea the act of sharing knowledge is a good deed worth
doing, no matter how trivial or esoteric the knowledge, and no matter if there's anyone listening.

To that end, I intend on keeping the lights on for this project for as long as I am able.
`

export default function PlaydateCPP(): ReactElement {
    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', marginTop: "10px"}}>
            <AppHeaderElement title={"Playdate CPP and Extensions"}/>
            <div style={{textAlign: "left", paddingBottom: "1px"}}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img
                        src={"/pdcpp_logo.png"}
                        alt="PlaydateCPP"
                        width={250}
                    />
                </Box>
                <ReactMarkdown children={mdpt1}/>

                <ReactMarkdown children={toUse}/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img
                        src={"/pdcppong.gif"}
                        alt="CPPong"
                    />
                </Box>
                <ReactMarkdown children={part2}/>
            </div>
        </Container>
    )
}