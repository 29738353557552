import React, {ReactElement} from 'react';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
    DroppableStateSnapshot
} from 'react-beautiful-dnd';
import DraggableModulator from "./draggable_modulator";
import Modulator from "../modulator_interfaces";
import { IconButton } from "@mui/material";
import {AddBox} from "@mui/icons-material";

const grid = 8;

// a little function to help us with reordering the result
const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});


interface ModListProps {
    modulators: Modulator[];
    onModChange: (newModulators: Modulator[]) => void;
}

export default function ModList(props: ModListProps): ReactElement {
    function onDragEnd(result: DropResult) {
        if (!result.destination) { return props.modulators; }
        const rv = Array.from(props.modulators);
        const [removed] = rv.splice(result.source.index, 1);
        rv.splice(result.destination?.index, 0 , removed);
    }
    const defaultMod: Modulator = {target: "hue", shape: "triangle", depth: 0.5, speed: 5};

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                    >{
                        props.modulators.map(
                            (mod, index) => (
                                <DraggableModulator index={index} modulator={mod} onModChange={(mod) => {
                                    // If there's no modulator, it's because it's been removed
                                    if (!mod) { props.modulators.splice(index, 1); }
                                    else      { props.modulators[index] = mod }
                                    props.onModChange(props.modulators);
                                }}/>)
                        )
                    }
                        {provided.placeholder}
                        <IconButton onClick={()=>{
                            const newModulator = {...props.modulators.length >= 0
                                ? props.modulators[props.modulators.length - 1]
                                : defaultMod}
                            props.onModChange([...props.modulators, newModulator]);
                        }}>
                            <AddBox/>
                        </IconButton>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}