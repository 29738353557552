import React, {ReactElement} from "react";
import {Routes, Route} from "react-router-dom";
import Home from "../components/home";
import {FitbitAuthCallback, FitbitAuthorize} from "../components/fitbit_leaderboard/auth";
import {FitbitActivityLeaderboard} from "../components/fitbit_leaderboard/leaderboard";
import LightDesignerApp from "../components/light_designer/main_interface";
import DrumPlyCalculatorApp from "../components/drum_ply_calculator/main";
import PlaydateProjectHome from "../components/playdate/main";
import PlaydateCPP from "../components/playdate/cpp_ext";


export default function URLRouter(): ReactElement {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/fitbit/auth" element={<FitbitAuthorize/>}/>
            <Route path="/fitbit/auth_callback" element={<FitbitAuthCallback/>}/>
            <Route path="/drums/ply_calculator" element={<DrumPlyCalculatorApp/>}/>
            <Route path="/fitbit/leaderboard" element={<FitbitActivityLeaderboard/>}/>
            <Route path="/light_designer" element={<LightDesignerApp/>}/>
            <Route path="/playdate" element={<PlaydateProjectHome/>}/>
            <Route path="/playdate/cpp" element={<PlaydateCPP/>}/>
        </Routes>
    );
}