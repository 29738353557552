import React, {ReactElement, useEffect} from "react";
import {GET} from "../../api/fetch_wrapper";
import Button from '@mui/material/Button'
import Container from "@mui/material/Container";
import {useNavigate, useSearchParams} from "react-router-dom";
import Cookies from "universal-cookie";
import AboutFitbitLeaderBoard from "./about";

interface FitbitAuthorizeAPIResponse {
    auth_uri: string;
}


export function FitbitAuthorize(): ReactElement {
    async function authorize() {
        console.log("Attempting log-in");
        const response = await GET<FitbitAuthorizeAPIResponse>("/fitbit/authorize");
        if (response?.data) {
            window.location.href = response.data.auth_uri;
        }
    }

    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', padding: "10px", marginTop: "10px"}}>
            <Container maxWidth="sm" style={{backgroundColor: '#fafafa', padding: "10px", marginTop: "10px"}}>
                <h1>Fitbit Activity Minutes Challenge:</h1>
                <Button variant="contained" onClick={authorize}>Join with Fitbit</Button>
            </Container>

            <AboutFitbitLeaderBoard/>
        </Container>
    )
}


interface FitbitAuthCallbackAPIResponse {
    status: "authorized" | "unauthorized";
    user_id: string;
    user_name: string;
}


export function FitbitAuthCallback(): ReactElement {
    const [params] = useSearchParams();
    const nav = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        async function fetch() {
            const response = await GET<FitbitAuthCallbackAPIResponse>(
                `/fitbit/authorize_callback/?code=${params.get("code")}&state=${params.get("state")}`
            );

            if (response?.data && response?.data?.status === "authorized") {
                cookies.set('user_id', response.data.user_id, {path: '/'});
                console.log(`Logged in as ${response.data.user_name}, ${cookies.get('user_name')}`);
                nav('/fitbit/leaderboard', { replace: true });
            }
        }

        fetch();
    });

    return (
        <div>{false}</div>
    )
}