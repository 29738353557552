import {DraggableProvided} from "react-beautiful-dnd";
import {CSSProperties} from "react";


export function getItemStyle(provided: DraggableProvided, grid: number): CSSProperties {
    return {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 ${grid}px 0 0`,
        // styles we need to apply on draggables
        ...provided.draggableProps.style,
    }
}
