import React, {ReactElement, useState} from "react";
import {
    Drawer,
    Divider,
    IconButton,
    List,
    ListItemText,
    Toolbar,
    useTheme,
    Box, ListItemButton, styled, Collapse, Button, ListItemSecondaryAction, ListItem
} from "@mui/material";
import {ChevronLeft, ChevronRight, ExpandLess, ExpandMore, Menu} from "@mui/icons-material";
import {useNavigate} from 'react-router-dom';
import Typography from "@mui/material/Typography";
import {DrawerHeader, HeaderBar, drawerWidth} from "./header_bar";
import URLRouter from "./router";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(8),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));


export function NavWrapper(): ReactElement {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);
    const [pdOpen, setPDOpen] = useState<boolean>(false);
    const nav = useNavigate();

    const handlePDMenu = () => {
        setPDOpen(!pdOpen);
    }

    return (
        <Box sx={{ display: 'flex'}}>
            <HeaderBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {setOpen(true);}}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <Menu/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                    </Typography>
                </Toolbar>
            </HeaderBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={() => { setOpen(false); }}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItemButton key="home" onClick={() => {nav("/")}}>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                    <ListItemButton key="drum-ply-calculator" onClick={() => {nav("/drums/ply_calculator")}}>
                        <ListItemText primary="Drum Ply Calculator" />
                    </ListItemButton>
                    <ListItemButton key="leaderboard" onClick={() => {nav("/fitbit/leaderboard")}}>
                        <ListItemText primary="Fitbit Leaderboard" />
                    </ListItemButton>
                    <ListItemButton key="light-designer" onClick={() => {nav("/light_designer")}}>
                        <ListItemText primary="Light Designer" />
                    </ListItemButton>
                    <ListItemButton key="playdate-home" onClick={()=>{}}>
                        <ListItemText primary="Playdate Projects" onClick={() => {nav("/playdate"); setPDOpen(true)}}/>
                        <IconButton onClick={()=> {setPDOpen(!pdOpen)}}>
                            { pdOpen ? <ExpandMore/> : <ExpandLess/>}
                        </IconButton>
                    </ListItemButton>
                    <Collapse in={pdOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton key="playdate-cpp"  style={{paddingLeft: theme.spacing(4)}} onClick={() => {nav("/playdate/cpp")}}>
                                <ListItemText primary="Playdate-CPP"/>
                            </ListItemButton>
                        </List>
                    </Collapse>

                </List>
            </Drawer>
            <Main>
                <URLRouter/>
            </Main>
        </Box>

    );
}
