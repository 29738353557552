interface HeartRateZone {
    caloriesOut: number;
    max: number;
    min: number;
    minutes: number;
    name: string;
}

interface HeartRateData {
    customHeartRateZones: HeartRateZone[];
    heartRateZones: HeartRateZone[];
    restingHeartRate: number;
}

export interface FitbitHeartRateTimeBlock {
    dateTime: string;
    value: HeartRateData;
}

export interface FitbitHeartRateResponse {
    stats: FitbitHeartRateTimeBlock[];
}

export interface MinutesBlock {
    active: number;
    cardio: number;
    peak: number;
}

export function simplify(x: FitbitHeartRateTimeBlock): MinutesBlock{
    return {
        active: x.value.heartRateZones[1].minutes,
        cardio: x.value.heartRateZones[2].minutes,
        peak: x.value.heartRateZones[3].minutes
    }
}

export function sumMinutes(x: MinutesBlock, y: MinutesBlock): MinutesBlock {
    return {
        active: x.active += y.active,
        cardio: x.cardio += y.cardio,
        peak: x.peak += y.peak
    };
}