import {Draggable} from "react-beautiful-dnd";
import React, {ReactElement, useState} from "react";
import {getItemStyle} from "./draggable";
import {HSLColour, HSLToCSSString} from "./colour_interfaces";
import {IconButton, Paper, Popover, PopoverOrigin, Stack} from "@mui/material";
import {Cancel, CheckBoxOutlineBlank} from "@mui/icons-material";
import { HslaColorPicker, HslaColor } from "react-colorful";


interface DraggableColourProps {
    index: number;
    colour: HSLColour;
    onColourChange: (colour: HSLColour|null) => void;
}


function makeHSLA(x: HSLColour): HslaColor {
    return {h: (1 - x.h) * 360, s: x.s * 100, l: x.l * 100, a: 100}
}


export default function DraggableColour(props: DraggableColourProps): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [color , setColor] = useState<HslaColor>(makeHSLA(props.colour))
    const anchor: PopoverOrigin = {horizontal: "right", vertical: "bottom"}

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) { setAnchorEl(event.currentTarget); }
    function handleClose() { setAnchorEl(null); }
    function handleDelete() { props.onColourChange(null); }

    function handleChange(newValue: HslaColor) {
        setColor(newValue);
        const hsl = {h: 1 - (newValue.h / 360), s: newValue.s / 100, l: newValue.l / 100}
        if (hsl !== props.colour) {
            props.onColourChange(hsl);
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const lighter = {...props.colour};
    lighter.l += 0.2;
    return (
        <Draggable key={`${props.index}`} draggableId={`${props.index}`} index={props.index}>
            {(provided, snapshot) => {
                const bgColour = snapshot.isDragging ? HSLToCSSString(lighter) : HSLToCSSString(props.colour)
                return (
                    <Stack alignItems="center" justifyContent="center" direction="column"
                           ref={provided.innerRef}
                           {...provided.draggableProps}
                           {...provided.dragHandleProps}
                           style={getItemStyle(provided, 8)}>
                        <Paper style={{backgroundColor: bgColour}}>
                            <IconButton disableRipple={true} onClick={handleClick} sx={{padding: "0px"}}>
                                <CheckBoxOutlineBlank sx={{opacity: 0.0}}/>
                            </IconButton>
                        </Paper>
                        <IconButton onClick={handleDelete} size="small" sx={{padding: "0px", paddingTop:"5px"}}>
                            <Cancel/>
                        </IconButton>
                        <Popover id={id} open={open} onClose={handleClose} anchorEl={anchorEl} anchorOrigin={anchor}>
                            <HslaColorPicker color={color} onChange={handleChange}/>
                        </Popover>
                    </Stack>
                )
            }}
        </Draggable>
    )
}
