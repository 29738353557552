

export interface HSLColour {
    h: number;
    s: number;
    l: number;
}

export interface RGBColour {
    red: number;
    green: number;
    blue: number;
}


function hue2rgb(p: number, q: number, t: number){
    if(t < 0) t += 1;
    if(t > 1) t -= 1;
    if(t < 1/6) return p + (q - p) * 6 * t;
    if(t < 1/2) return q;
    if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
    return p;
}


export function hslToRgb(hsl: HSLColour): RGBColour {
    var r, g, b;

    if (hsl.s === 0) {
        r = g = b = hsl.l; // achromatic
    }
    else {
        var q = hsl.l < 0.5
              ? hsl.l * (1 + hsl.s)
              : hsl.l + hsl.s - hsl.l * hsl.s;

        var p = 2 * hsl.l - q;

        r = hue2rgb(p, q, hsl.h + 1/3);
        g = hue2rgb(p, q, hsl.h);
        b = hue2rgb(p, q, hsl.h - 1/3);
    }

    return { red: Math.round(r * 255), green: Math.round(g * 255), blue: Math.round(b * 255)};
}


export function componentToHex(c: number): string {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}


export function HSLToCSSString(colour: HSLColour): string {
    const rgb = hslToRgb(colour);
    return `#${componentToHex(rgb.red)}${componentToHex(rgb.blue)}${componentToHex(rgb.green)}`;
}
