import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";

const md = `
# About the Fitbit Leaderboard
The goal is simple: Use your Fitbit activity tracker and compete to see which of your friends can be at the top of the
board by the end of each week.

## To Use
1. Get an activity tracker that can measure your heart rate and publish the metrics to Fitbit
2. Sign up with this leaderboard and give the App permission to access your data (I do not save, or do anything else 
with this data, I just plot it against your friends.)
3. Get your friends to at least follow steps one and two
4. Get out there and be active
5. Come back each week to see who won
6. Select a player's name to see their per-day activity breakdown 

There are two awards: The Crown and The Heart. If you win a week (measured from midnight Sunday PST) you get The Crown.
If you meet or exceed the 150 Active Minutes as specified by the American Heart Association, you get The Heart. 

What you may think of as "Exercise" isn't required, it's about getting the heart rate up for sustained periods of time.
Fitbit measures various heart rate zones: you get a point for every minute you are in the "elevated" zone, and 1.5
points for every minute you are in the "cardio" or "peak" zones. The higher zones are more commonly associated with
harder exercise, so making it to the top of your leaderboard is easier if you do that, but in my experience it's really
consistency that wins (also true of a lot of things.) My mother will frequently trounce the whole family by going for a
walk every day in weeks where the rest of us go really hard twice. 
 
## History
My family and I have always enjoyed a bit of friendly competition. One year, to encourage us to get out and move more, 
my father purchased us Fitbits (back before they were owned by Google,) and challenged us to meet the American Heart
Association's guidelines for activity every week.

To sweeten the deal, my brother demonstrated that one could have challenges every week against your friends to see who
could get the best score for any metric that Fitbit calculated, but there was a catch: if the metric wasn't steps, the
challenge had to be manually scheduled every single week, and there was no way to do recurrence.  Additionally, the
scores for "Activity Minutes" had some obfuscating bonus math applied which made the true measure harder to discern at a
glance.

I found this all to be annoying. It was not anywhere near as simple, user friendly, or clear as any of us had wanted.
As a result, I went out and got an API key from Fitbit, put together some back-end infrastructure with AWS to hold
everything together, and we've been using this leaderboard to track, our standings for almost two years now. It's been
a great way to encourage activity.
   
Perhaps with Google's takeover, they've implemented this as well by this point, but even if they have I'll be keeping
this app up and running even if it's just for posterity. It will really only break if I decide I don't want to pay the
peanuts it costs a month to operate, or if Google breaks or turns off the API.
`

export default function AboutFitbitLeaderBoard(): ReactElement {
    return (
        <div style={{textAlign: "left", paddingBottom: "1px" }}>
            <ReactMarkdown children={md}/>
        </div>
    );
}
