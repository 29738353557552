import React, {ReactElement, useEffect} from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {NavWrapper} from "./navigation/nav_wrapper";
import moment from 'moment';
import {} from 'date-fns/set'

moment.updateLocale("en", {
    week: {
        dow: 1
    }
});

function App(): ReactElement {
    useEffect(() => {
        document.title = "Apps from Matt Zapp"
    });
    return (
        <div className="App">
            <BrowserRouter>
                <NavWrapper/>
            </BrowserRouter>
        </div>
    );
}

export default App;
