import {MinutesBlock} from "../../api/interfaces/fitbit_activity";
import React, {ReactElement, useState} from "react";
import {Avatar, Chip, Collapse, ListItem, ListItemText} from "@mui/material";
import {CrownIcon} from "../../style/crown_icon";
import AhaIcon from "../../aha_icon.svg";
import LeaderboardChart from "./chart";


export interface LeaderboardEntryProps {
    user_id: string;
    username: string;
    avatar: string;
    data: MinutesBlock[];
    total_minutes: number;
    is_leader: boolean;
}

export function LeaderboardEntry(props: LeaderboardEntryProps): ReactElement {
    const [open, setOpen] = useState<boolean>(false);

    function handleClick() {
        console.log(props.user_id);
        setOpen(!open);
    }

    return (
        <React.Fragment>
            <ListItem button onClick={handleClick} key={props.user_id}>
                <Avatar src={props.avatar} sx={{marginRight: "10px"}}/>
                <ListItemText primary={props.username}/>
                { props.is_leader ? (<CrownIcon sx={{paddingTop: "4px", color: "gold"}}/>) : null }
                { props.total_minutes >= 150 ? (<img alt="aha" src={AhaIcon} style={{paddingBottom: "1px"}}/>) : null }
                <Chip label={props.total_minutes} sx={{backgroundColor: "grey"}}/>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <LeaderboardChart data={props.data}/>
            </Collapse>
        </React.Fragment>
    );
}