import {Draggable, DraggableProvided} from "react-beautiful-dnd";
import React, {CSSProperties, ReactElement, useEffect, useState} from "react";
import Modulator from "../modulator_interfaces";
import {getItemStyle} from "../draggable";
import {
    IconButton,
    Paper,
    Popover,
    PopoverOrigin,
    Stack
} from "@mui/material";
import {Cancel, CheckBoxOutlineBlank} from "@mui/icons-material";
import ModulatorEditor from "./editor";

interface DraggableModProps {
    index: number;
    modulator: Modulator;
    onModChange: (colour: Modulator|null) => void;
}



export default function DraggableModulator(props: DraggableModProps): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const anchor: PopoverOrigin = {horizontal: "right", vertical: "bottom"}

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) { setAnchorEl(event.currentTarget); }
    function handleClose() { setAnchorEl(null); }
    function handleDelete() { props.onModChange(null); }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Draggable key={`${props.index}`} draggableId={`${props.index}`} index={props.index}>
            {(provided, snapshot) => {
                const bgColour = snapshot.isDragging ? "#555555" : "#aaaaaa";
                return (
                    <Stack alignItems="center" justifyContent="center" direction="column"
                           ref={provided.innerRef}
                           {...provided.draggableProps}
                           {...provided.dragHandleProps}
                           style={getItemStyle(provided, 8)}>
                        <Paper style={{backgroundColor: bgColour}}>
                            <IconButton disableRipple={true} onClick={handleClick} sx={{padding: "0px"}}>
                                <CheckBoxOutlineBlank sx={{opacity: 0.0}}/>
                            </IconButton>
                        </Paper>
                        <IconButton onClick={handleDelete} size="small" sx={{padding: "0px", paddingTop:"5px"}}>
                            <Cancel/>
                        </IconButton>
                        <Popover id={id} open={open} onClose={handleClose} anchorEl={anchorEl} anchorOrigin={anchor}>
                            <ModulatorEditor {...props.modulator} onModulatorChange={props.onModChange}/>
                        </Popover>
                    </Stack>
                )
            }}
        </Draggable>
    )
}
