/** @jsxImportSource @emotion/react */
import React, {ReactElement} from 'react';
import {Box, Paper, } from "@mui/material";
import {css, keyframes} from "@emotion/react"
import {HSLColour, HSLToCSSString} from "./colour_interfaces";
import tinygradient from "tinygradient";


interface ColourResultDisplayProps {
    lengthSeconds: number;
    colours: HSLColour[];
}


function convertColoursToKeyframes(colours: HSLColour[]): string {
    // First, bookend the loop:
    colours = [...colours, colours[0]];

    const gradient = tinygradient(colours.map((x, i) => ({
            color: HSLToCSSString(x),
            pos: i / (colours.length - 1)
        })
    ))

    console.log(gradient);

    return gradient.hsv(101, "short").map((x, i) => {
        return `${i}% {background-color: ${x.toHexString()}}`;
    }).join("\n")
}

export default function ColourResultDisplay(props: ColourResultDisplayProps): ReactElement {
    const animation = keyframes`${convertColoursToKeyframes(props.colours)}`;

    const cssClass = css`
      animation: ${animation};
      animation-duration: ${props.lengthSeconds}s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-play-state: running;
    `
    return (
        <Box sx={{display: 'flex', flexWrap: 'wrap', '& > :not(style)': {m: 1, width: 128, height: 128,},}}>
            <Paper elevation={1} css={cssClass}/>
        </Box>
    )
}
